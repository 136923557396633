"use strict";
require("jquery-colorbox");

// call now popup window
$(".callNow__icon222").colorbox({
  inline: true,
  width: "90%",
  maxWidth: "400px",
});

// test drive popup window
$(".testDrive__icon").colorbox({
  inline: true,
  width: "90%",
  maxWidth: "400px",
});

// order consultation popup window
$(".orderConsultation__icon").colorbox({
  inline: true,
  width: "90%",
  maxWidth: "430px",
});

// close btn
$(".close-btn-popup").click(function () {
  $.colorbox.close();
});

// disable scroll when popup open
$(document)
  .bind("cbox_open", function () {
    $("html").addClass("noScrollSimple");
  })
  .bind("cbox_cleanup", function () {
    $("html").removeClass("noScrollSimple");
  });

// search form popup
var search = $(".search-icon, .search-icon-2").colorbox({
  inline: true,
  width: "90%",
  maxWidth: "400px",
  onLoad: function () {
    $("#search-form-box").show();
    $("body").addClass("openSearchBar");
  },
  onCleanup: function () {
    $("#search-form-box").hide();
  },
  onClosed: function () {
    $("body").removeClass("openSearchBar");
  },
});

// video in popup box from youtube, vimeo src with auto play
(function ($) {
  $(".video-source[href*='vimeo.com']").each(function () {
    var href = $(this).attr("href");
    var vimeoId = href.split("/").pop();
    $(this).colorbox({
      iframe: true,
      closeButton: false,
      width: "85%",
      height: "85%",
      maxWidth: "95%",
      maxHeight: "95%",
      href: function () {
        return "http://player.vimeo.com/video/" + vimeoId + "?autoplay=1";
      },
    });
  });
  $(".video-source[href*='youtube.com']").each(function () {
    var href = $(this).attr("href");
    var youtubeId = href.split("=").pop();
    $(this).colorbox({
      iframe: true,
      closeButton: false,
      width: "85%",
      height: "85%",
      maxWidth: "95%",
      maxHeight: "95%",
      href: function () {
        return "http://www.youtube.com/embed/" + youtubeId + "?autoplay=1";
      },
    });
  });
})(jQuery);
